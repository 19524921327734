import Sidebar from "./SideBar.vue";
import SidebarLink from "./SidebarLink";
// import { createApp } from 'vue';


const SidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  displaySidebar(value) {
    this.showSidebar = value;
  },
};

const SidebarPlugin = {
  install(Vue) {

    Vue.config.globalProperties.$sidebar = SidebarStore;

    Vue.component("side-bar", Sidebar);
    Vue.component("sidebar-link", SidebarLink);
  },
};

export default SidebarPlugin;
