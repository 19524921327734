export function getApiBaseUrl() {
    const hostname = window.location.hostname;

    // Check if the current hostname is a subdomain of 'local.domain'
    if (hostname.endsWith(process.env.VUE_APP_API_BASE_SUBDOMAIN)) {
        // Use the current subdomain in the API base URL
        return `https://${hostname}`;
    }

    // Default to the environment variable if no match
    return process.env.VUE_APP_API_BASE_URL;
}

// Function to retrieve the value of a cookie
export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function getEnv() {
    return process.env.VUE_APP_ENV;
}

export function getSubdomain() {
    return process.env.VUE_APP_API_BASE_SUBDOMAIN;
}

export function parseDate(date) {
    if (date) {
        return new Date(date).toLocaleString();
    }
    return "";
}