import { createApp } from 'vue';
import App from './AppUser.vue';
import router from './router/user';
import { createPinia } from 'pinia';
import 'normalize.css/normalize.css';
import Notifications from '@kyvg/vue3-notification';
import BlackDashboard from "./plugins/blackDashboard";
import Antd from 'ant-design-vue';

const pinia = createPinia();

createApp(App)
    .use(Antd)
    .use(router)
    .use(BlackDashboard)
    .use(pinia)
    .use(Notifications)
    .mount('#app');
