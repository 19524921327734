// stores/websocket.js
import { defineStore } from 'pinia';
import { getApiBaseUrl } from '../utils';

const baseURL = getApiBaseUrl();
export const useWebSocketStore = defineStore('websocket', {
  state: () => ({
    socket: null,
    messages: [],
    isConnected: false,
    selectedChat: "",
    threads: [],
    newMessage: null
  }),
  actions: {
    initializeWebSocket() {
      // Create a new WebSocket connection
      let url = `${baseURL}/api/chat/ws`.replace('https', '');
      this.socket = new WebSocket(`wss${url}`);

      // Event handler for when the connection is successfully opened
      this.socket.onopen = () => {
        console.log('WebSocket connection opened.');
        this.isConnected = true;

        // Send a message to the server
        var request = {
          "action": "GET_ALL_CHATS"
        };
        this.socket.send(JSON.stringify(request));
      };

      // Event handler for when a message is received from the server
      this.socket.onmessage = (event) => {
        console.log('Message received from server:', event.data);
        this.message = event.data; // Update the state with the received message

        const response = JSON.parse(event.data);

        this.newMessage = response;

        if (response.action == "GET_ALL_CHATS") {
          this.threads = response.data;
        }
        else if (response.action == "START_CHAT") {
          var request = {
            "action": "GET_ALL_CHATS"
          };
          this.socket.send(JSON.stringify(request));
        }

      };

      // Event handler for when the connection is closed
      this.socket.onclose = (event) => {
        this.isConnected = false;
        if (event.wasClean) {
          console.log(`WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`);
        } else {
          console.log('WebSocket connection closed unexpectedly');
        }
      };

      // Event handler for when an error occurs
      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    },
    closeWebSocket() {
      if (this.socket) {
        this.socket.close(); // Close the WebSocket connection
        this.isConnected = false;
      }
    },
    sendMessage(message) {
      if (this.socket && this.isConnected) {
        this.socket.send(message); // Send a message to the server
      } else {
        console.error('WebSocket is not connected.');
      }
    },
    setSelectedChat(uuid) {
      this.selectedChat = uuid;
      if (uuid) {
        var request = {
          "action": "GET_CHAT",
          "chat_uuid": uuid
        };
        this.sendMessage(JSON.stringify(request))
      }
      else {
        this.messages = [];
      }
    }
  },
});
